<template>
    <div class="box is-marginless">
        <columns>
            <column class="is-narrow">
                <colour-picker 
                    :value="column.colour"
                    @input="updateColumnColour" classes="is-small is-rounded" />
            </column>
            <column>
                {{ column.label }}
            </column>
            <column class="is-narrow">
                <action-button @click="removeColumn(column)" class="is-round is-small is-danger">
                    <icon icon="trash" />
                </action-button>
            </column>
        </columns>
    </div>
</template>
<script>
export default {
    props: {
        column: {
            type: Object,
            default: () => ({})
        }
    },

    beforeDestroy() {
        this.$commit('reportWidget/clearReportWidget')
    },

    methods: {
        updateColumnColour(colour) {
            this.$store.commit('reportWidget/updateColumnColour', {
                column: this.column,
                colour
            })
        },
        async removeColumn(column) {
            if(await this.$confirm({
                title: 'Remove Data Point',
                message: 'Are you sure you what to remove this datapoint?'
            })) {
                this.$store.commit('reportWidget/removeColumn', column)
            }
        }
    }
}
</script>