<template>
    <div>
        <data-selector
            classes="is-rounded"
            :items="statuses"
            multiple
            :value="selected"
            @input="updateSelectedColumns"
            :error="$root.errors.columns">
            Outcomes <small class="has-text-weight-light">(All if none are selected)</small>
        </data-selector>

        <columns>
            <column>
                <h4 class="title is-4">Widget Data</h4>
                <div class="index-rows">
                    <column-builder :column="column" v-for="(column, index) in widget.columns" :key="index"/>
                </div>
            </column>
        </columns>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ColumnBuilder from './ColumnBuilder.vue'

export default {

    components: {
        ColumnBuilder
    },

    data: () => ({
        selected: [], 
        statuses: [
            { value: 'total', label: 'Total', colour: '#4064BF' },
            { value: 'passed', label: 'Passed', colour: '#49BF40' },
            { value: 'failed', label: 'Failed', colour: '#BF4040' },
            { value: 'complete', label: 'Complete', colour: '#6C40BF' },
        ]
    }),

    methods: {
        updateSelectedColumns(selected) {
            this.selected = selected
            const columns = this.selected.map(status => {
                if (status.value === 'total') {
                    return {
                        label: 'Total',
                        colour: status.colour
                    }
                }

                return {
                    label: status.label,
                    key: 'status',
                    value: status.value,
                    colour: status.colour
                }
            })
            this.$store.commit('reportWidget/updateSelectedColumns', columns)
        }
    },

    computed: {
        ...mapGetters('reportWidget', [
            'widget'
        ]),
    }

}
</script>